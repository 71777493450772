import { getArticle } from '@/storyblok/helpers/getArticle'
import { useArticleTags } from '@/storyblok/hooks/useArticleTags'

/**
 * Process Storyblok data that can be passed to block components as props
 *
 * @param {array} data
 */

export const useArticles = (data) => {
  const tags = useArticleTags()
  const articles = []

  data?.forEach(({ node }) => {
    if (node && typeof node.content === 'string') {
      node.content = JSON.parse(node.content)
    }
    articles.push(getArticle(node, { allTags: tags }))
  })

  return articles
}
