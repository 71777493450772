import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@/blocks/Text'
import * as styles from './Error.styles.scss'

const Error = ({ title, children }) => (
  <div className={styles.Error}>
    <Text heading={title}>
      <div className={styles.errorMessage}>{children}</div>
    </Text>
  </div>
)

Error.defaultProps = {
  title: undefined,
  children: undefined,
}

Error.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default Error
