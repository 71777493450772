import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'
import { useArticles } from '@/storyblok/hooks/useArticles'
import { getFilters } from '@/storyblok/helpers/getFilters'
import Articles from '@/templates/Articles'

const ArticlesStoryblok = ({ story, articles }) => {
  // Filters
  const filters = getFilters([
    {
      uid: 'filter-category',
      slug: 'category',
      items: story.content?.categories,
    },
  ])

  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Articles
        articles={useArticles(articles)}
        filters={filters}
        filtersHeading={story.content.filters_heading}
      />
    </SbEditable>
  )
}

ArticlesStoryblok.propTypes = {
  story: PropTypes.object,
  articles: PropTypes.arrayOf(PropTypes.object),
}

export default ArticlesStoryblok
