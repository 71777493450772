import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/components/Link'
import { Container } from '@/components/Container'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'
import Error from '@/templates/Error'

import Page from '@/storyblok/templates/Page.storyblok'
import Project from '@/storyblok/templates/Project.storyblok'
import Article from '@/storyblok/templates/Article.storyblok'
import Projects from '@/storyblok/templates/Projects.storyblok'
import Articles from '@/storyblok/templates/Articles.storyblok'

const templateTypes = {
  page: Page,
  project: Project,
  article: Article,
  projects: Projects,
  articles: Articles,
}

const NotFoundPage = ({ location }) => {
  const story = useStoryblok(null, location)

  // preview unpublished content
  if (story && story.content) {
    const Template = templateTypes[story.content.component]

    return Template ? (
      <Template story={story} />
    ) : (
      <Container>
        Error: Unpublished preview is not supported for this content type.
      </Container>
    )
  }

  return (
    <Error title="Page Not found">
      <p>
        If you entered a web address please check it was correct, or{' '}
        <Link to="/">click here</Link> to go to the homepage.
      </p>
    </Error>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object,
}

export default NotFoundPage
